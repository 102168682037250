/********************************************************************************************************
    cr-select2 is a widget that lets you search for, and accept an item using the select2 library.  It's defined as
    a widget with the name <cr-select2 and takes an options attribute with a ko-style binding string.  This string will
    have up to three objects therein, midTier, client, and select2.  Detailed docs are below, but the simplest working
    example would probably look like this.

    <cr-select2 style="width: 500px;"
                options="midTier: { source: 'channelId.action', list: 'contacts', display: 'Name' },
                         client: { id: contactId, display: contactName }">
    </cr-select2>

    midTier:    an object specifying properties relating to the mid tier query
        source      Can be a string of the mid tier action to call, ie channelId.action
                    Can be a method on the current viewModel accepting the search term, and returning a promise that
                        will resolve with the appropriate mid tier response.
                    Can be a computed on the viewModel that returns the options to choose from (see searchSync below)
                    If mid tier string, then search, and searchTerm will be passed to the mid tier as the typed search term
        list        The name of the array on the response with the search results
        id          DEFAULTS: 'id', or 'Id' in that order
                    The id property on each response object in the list
        display     The property on each response object that will be used for display
        useElastic  (optional) If true, will attempt to find an equivalent elastic search api request for the midtier source
                    that was provided and request against that instead of the midtier.

    client:     an object specifying how the client viewModel will react to interactions with the select2 widget
        *** Standard Usage ***
        id          The viewModel property holding the id of the item that's currently selected.  If this is ko validated
                    then the cr-select will show validation errors as they come up.
        display     viewModel property holding the display of the current item (UI will initially render with its value).
                    NOTE: If you don't specify an id or a validationProperty (below) and this property is ko validated,
                    then that validation will be tied in
        onClear     Controls whether a clear button will appear in the select2 widget.  If true, then the clear button
                    will clear (to empty string) the id and display values.  If a function is specified, then that
                    will be called for you to manually do any clearing you need (the vm properties will NOT be auto-cleared).
                    NOTE: if you use onSelect (below) instead of the id and display properties, then a function will
                    be your only choice since there are no properties to auto-clear.

        *** Non-standard Usage ***
        onSelect    The viewModel function to be called when you select an item from the list.  The actual, raw item
                    (as returned from the mid tier) will be passed in.  Do with it as you please
                    ********NOTE******** You may need the neverSet option, below under select2, with this
        textSelect  If specified, the text you type in will appear as a selectable result.  If you pick it, the function
                    specified here will be called, and the raw text passed in
        validationProperty
                    If you're not specifying a vmId (or really, even if you are) you can manually configure this to be
                    the property against which the select2 is validated.
        searchSync  An observable on the viewModel that's kept in sync with what the user types in the search box.  Most
                    likely useful for when your source is a computed.  It can use this to filter based on what the user types

    select2:    an object specifying select2 options
        renderResultItem
                    A function to format each search result item.  For a free text selection, an object with
                        type === 'rawtext' and name === the raw string will be passed.
                    Else, the object from the mid tier will be passed.
                    NOTE: the second argument will be the base, default method, which can be called if you only wish to
                    provide custom formatting in certain circumstances
        neverSet    If true, causes the select2 to never actually set the selected item.  Useful if you're using this
                    widget to just repeatedly select items to add to a collection.
        minimumInputLength
                    Self explanatory
                    DEFAULT: 3
        queryDelay
                    ibid
                    DEFAULT: 500ms
        placeholder
                    ibid
                    DEFAULT 'Search'
        enable      ibid

*/
define(["framework/globalUtils/koNodePreprocessor", "framework/koMapper/mapper", "app/util/elasticLookupMapper", "app/util/transport"], function(
  widgets,
  koMapper,
  ElasticLookupMapper,
  transportModule
) {
  const Api = transportModule.default.api;

  widgets.addElementHandler({
    type: "cr-select2",
    process: function($node) {
      var select2String = "crSelect2: {" + $node.attr("options") + "}";
      return $("<span></span>").append(widgets.mapAttributes($node, $('<input type="hidden" data-bind="' + select2String + '" />')));
    }
  });

  const configSections = ["midTier", "client", "select2Options"];

  const presets = {
    lookupSearch(types) {
      return {
        midTier: { source: "shared.lookup", list: "results", id: "id", display: "displayName", staticSearchParams: { types } }
      };
    }
  };

  const getPreset = new Function(
    "presets",
    "presetExpression",
    `
        with(presets){
            return eval(presetExpression);
        }
    `
  );

  ko.bindingHandlers.crSelect2 = {
    init: function(element, valueAccessor, ab, viewModel, bindingContext) {
      var vaUnrolled = valueAccessor(),
        $element = $(element);

      if ($element.attr("preset")) {
        let val = $element.attr("preset");
        let presetValues = getPreset(presets, $element.attr("preset"));

        configSections.forEach(prop => (vaUnrolled[prop] = Object.assign({}, presetValues[prop] || {}, vaUnrolled[prop] || {})));
      }

      var midTier = vaUnrolled.midTier,
        client = vaUnrolled.client,
        select2Options = vaUnrolled.select2 || {},
        //------------------------------------------------
        src = midTier.source,
        respList = midTier.list,
        optionDisplay = midTier.display,
        staticSearchParams = midTier.staticSearchParams || {},
        optionId = midTier.id,
        useElastic = midTier.useElastic,
        //------------------------------------------------
        vmId = client.id,
        vmDisplay = client.display,
        onSelect = client.onSelect,
        textSelect = client.textSelect,
        onClear = client.onClear,
        validationProperty = client.validationProperty || vmId || vmDisplay,
        searchSync = client.searchSync,
        preprocess = client.preprocess,
        //------------------------------------------------
        renderResultItem = select2Options.renderResultItem,
        placeholderRaw = select2Options.placeholder,
        placeholder = typeof placeholderRaw === "undefined" ? "Search" : ko.unwrap(placeholderRaw),
        minimumInputLength = select2Options.minimumInputLength || (ko.isObservable(src) ? 0 : undefined),
        queryDelay = select2Options.queryDelay,
        enableBinding = select2Options.enable,
        neverSet = select2Options.neverSet,
        //-------------------------------------------------
        isValidated = validationProperty && validationProperty.isValid && validationProperty.clearError,
        widgetUpdating = false;

      var select2Options = {
        placeholder: placeholder,
        query: query,
        minimumInputLength: typeof minimumInputLength === "undefined" ? 3 : minimumInputLength,
        formatResult: function(item) {
          return (renderResultItem || getTextForItem)(item, getTextForItem);
        },
        formatSelection: getTextForItem,
        allowClear: !!onClear,
        id: ko.isObservable(src)
          ? function(item) {
              return ko.unwrap(item[optionId || "id"] || item["Id"]);
            }
          : undefined,
        initSelection: function(el, callback) {
          callback({ id: "", name: "", type: "init" }); //I need this crap so my containerCssClass will be called
        },
        containerCssClass: function() {
          var withError = isValidated && !validationProperty.isValid() && validationProperty.isModified();
          return withError ? "cr-invalid" : "";
        }
      };

      var textResultId = -1;

      $element.select2(select2Options);
      vmDisplay && vmDisplay() && updateSelect2WithManualChange();

      if (typeof enableBinding !== "undefined") {
        $element.select2("enable", ko.unwrap(enableBinding));
        if (ko.isObservable(enableBinding)) {
          enableBinding.subscribe(function(val) {
            $element.select2("enable", ko.unwrap(!!val));
          });
        }
      }

      if (isValidated) {
        var errorSpan = $('<span class="error" data-bind="validationMessage: val"></span>');
        errorSpan.insertAfter($element);

        ko.applyBindings({ val: validationProperty }, errorSpan[0]);
        //ko.applyBindingsToNode(errorSpan[0], { val: validationProperty }, bindingContext);
      }

      if (placeholderRaw && placeholderRaw.subscribe) {
        var placeholderSub = placeholderRaw.subscribe(function(val) {
          $element.attr("data-placeholder", val);
          $element.data("select2").setPlaceholder();
        });
      }

      var subscriptionsToDispose = [];
      [vmId, vmDisplay].forEach(function(obs) {
        obs && subscriptionsToDispose.push(obs.subscribe(updateSelect2WithManualChange));
      });
      function updateSelect2WithManualChange() {
        if (widgetUpdating) return; //user is interacting with select2 - let the library update itself

        var idVal = vmId ? vmId() : "",
          newData = { Id: idVal, id: idVal };
        newData[optionDisplay] = vmDisplay();
        if (newData[optionDisplay] && !idVal) {
          //it's possible we had saved a text value, which wouldn't really have an ID (and no - DON'T save this one).  So we give it an arbitrary and unique one here
          newData.id = newData.Id = textResultId--;
        }
        $element.select2("data", newData.Id ? newData : null);
      }

      $element.on("change", function(o, b, c) {
        var obj = o.added;
        widgetUpdating = true;
        if (!obj) {
          if (typeof onClear === "function") {
            onClear.call(viewModel);
          } else if (onClear) {
            vmId && vmId("");
            vmDisplay && vmDisplay("");
          }
        } else {
          if (obj.type === "rawtext") {
            textSelect.call(viewModel, obj.name);
          } else {
            vmId && vmId(obj.id);
            vmDisplay && vmDisplay(obj[optionDisplay]);
            onSelect && onSelect.call(viewModel, obj);
          }
        }
        if (neverSet) {
          $element.select2("data", null);
        }
        widgetUpdating = false;
      });

      function getTextForItem(item) {
        if (item.type === "rawtext") {
          return item.name;
        }
        return ko.unwrap(item[optionDisplay]);
      }

      var queryTimeout;

      function query(packet) {
        textSelect && packet.callback({ results: [{ id: textResultId--, name: packet.term, type: "rawtext" }] });

        searchSync && searchSync(packet.term);
        if (ko.isObservable(src)) {
          var result = [];
          if (textSelect && packet.term) {
            result.push({ name: packet.term, type: "rawtext" });
            result[0][optionId || "id"] = textResultId--;
          }
          packet.callback({ results: result.concat(src()) });
        } else {
          clearTimeout(queryTimeout);
          queryTimeout = setTimeout(function() {
            if ($element.data("select2").search.val() !== packet.term) return;

            textSelect &&
              packet.callback({
                results: [{ id: textResultId--, name: packet.term, type: "rawtext" }]
              });

            if (typeof src === "string") {
              if (src === "shared.lookup") {
                var optionsQuery =
                  "lookupOptions=" +
                  staticSearchParams.types.map(type => `{LookupType:${type},OrganizationIdCondition:Default,State:Active}`).join(",");
                Api.get(`search/lookups?query=${encodeURIComponent(packet.term)}&${optionsQuery}`).then(resp => ajaxCallback(packet, resp));
              } else if (ElasticLookupMapper.default.hasElasticEquivalent(src)) {
                // translate to elastic
                const apiTier = ElasticLookupMapper.default.mapMidTierToApi(src);

                // unwrap it
                (respList = apiTier.list), (optionDisplay = apiTier.display), (optionId = apiTier.id);

                // hit the api
                Api.get(`search/lookups?query=${encodeURIComponent(packet.term)}&${apiTier.source}`).then(resp => ajaxCallback(packet, resp));
              } else {
                let requestPacket = { search: packet.term, searchTerm: packet.term };
                if (staticSearchParams) {
                  Object.assign(requestPacket, staticSearchParams);
                }
                cr.transport.transmitRequest(src.split(".")[0], src.split(".")[1], requestPacket, function(resp) {
                  ajaxCallback(packet, resp);
                });
              }
            } else if (typeof src === "function") {
              src.call(viewModel, packet.term).then(function(resp) {
                ajaxCallback(packet, resp);
              });
            }
          }, queryDelay || 500);
        }
      }

      function ajaxCallback(packet, resp) {
        var results = [],
          processedResp = typeof preprocess === "function" ? preprocess(resp, packet.term) : resp;
        textSelect && results.push({ id: textResultId--, name: packet.term, type: "rawtext" });

        packet.callback({
          results: results.concat(
            resp[respList].map(item => {
              return $.extend(item, { id: item[optionId] || item.id || item.Id, Id: item[optionId] || item.id || item.Id });
            })
          )
        });
      }

      ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
        placeholderSub && placeholderSub.dispose();
        subscriptionsToDispose.forEach(function(sub) {
          sub.dispose();
        });

        $element.select2("destroy");
        $element = null;
      });
    }
  };
});
